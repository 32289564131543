
import {
    PlusOutlined
} from '@ant-design/icons-vue';
import MenuEdit from './user-menu-edit';
import RoleAuth from "./components/role-auth.vue"

export default {
    name: 'SystemMenu',
    components: {
        PlusOutlined,
        MenuEdit,
        RoleAuth
    },
    data() {
        return {
            // 表格数据接口
            url: '/admin/menu/user/list',
            // 表格搜索条件
            where: {},
            // 表格选中数据
            selection: [],
            // 当前编辑数据
            current: null,
            // 是否显示编辑弹窗
            showEdit: false,
            // 表格展开的行
            expandedRowKeys: [],
            // 全部菜单数据
            menuList: [],
            // 权限弹窗
            showRoleAuth: false
        };
    },
    computed: {
        // 表格列配置
        columns() {
            return [{
                    key: 'index',
                    dataIndex: 'menu_index',
                    width: 48,
                    align: 'center',
                    customRender: ({
                        index
                    }) => index + 1
                },
                {
                    title: this.$t('菜单名称'),
                    dataIndex: 'title',
                    sorter: true
                },
                {
                    title: this.$t('路由地址'),
                    dataIndex: 'path',
                    sorter: true
                },
                {
                    title: this.$t('组件路径'),
                    dataIndex: 'component',
                    sorter: true
                },
                {
                    title: this.$t('排序'),
                    dataIndex: 'index'
                },
                {
                    title: this.$t('可见'),
                    dataIndex: 'enabled',
                    sorter: true,
                    customRender: ({
                        text
                    }) => ['否', '是'][text ? 1 : 0]
                },
                {
                    title: this.$t('创建时间'),
                    dataIndex: 'created',
                    sorter: true
                },
                {
                    title: this.$t('操作'),
                    key: 'action',
                    width: 220,
                    align: 'center',
                    slots: {
                        customRender: 'action'
                    }
                }
            ]
        }
    },
    methods: {
        /**
         * 解析接口返回数据
         * @param res
         */
        parseData(res) {
            res.data = this.$util.toTreeData(res.data.map(d => {
                d.key = d.id;
                d.value = d.id;

                return d;
            }), 'id', 'parent_id');
            if (!Object.keys(this.where).length) {
                this.menuList = res.data;
            }
            if (!this.expandedRowKeys.length) {
                this.expandAll();
            }
            return res;
        },
        /**
         * 刷新表格
         */
        reload() {
            this.$refs.table.reload();
        },
        /**
         * 重置搜索
         */
        reset() {
            this.where = {};
            this.$nextTick(() => {
                this.reload();
            });
        },
        /**
         * 打开编辑弹窗
         * @param row
         * @param parentId
         */
        openEdit(row, parent_id) {
            this.current = Object.assign({
                parent_id: parent_id
            }, row);
            this.showEdit = true;
        },
        /**
         * 删除单个
         * @param row
         */
        remove(row) {
            if (row.children && row.children.length > 0) {
                this.$message.error('请先删除子节点');
                return;
            }
            const hide = this.$message.loading('请求中..', 0);
            this.$http.post('/admin/menu/delete', {
                ids: [row.id]
            }).then(res => {
                hide();
                if (res.data.code === 0) {
                    this.$message.success(res.data.msg);
                    this.reload();
                } else {
                    this.$message.error(res.data.msg);
                }
            }).catch(e => {
                hide();
                this.$message.error(e.message);
            });
        },
        /**
         * 展开全部
         */
        expandAll() {
            let keys = [];
            this.$util.eachTreeData(this.menuList, (d) => {
                if (d.children && d.children.length) {
                    keys.push(d.id);
                }
            });
            this.expandedRowKeys = keys;
        },
        /**
         * 折叠全部
         */
        foldAll() {
            this.expandedRowKeys = [];
        },
        /**
         * 展开的行变化
         * @param expandedRows
         */
        onExpandedRowsChange(expandedRows) {
            this.expandedRowKeys = expandedRows;
        },
        /**
         * 判断是否是网址
         * @param url
         */
        isUrl(url) {
            return url && (
                url.startsWith('http://') ||
                url.startsWith('https://') ||
                url.startsWith('://'));
        },
        /**
         * 打开权限弹窗
         * @param row 当前菜单对象
         */
        openRoleAuth(row) {
            this.showRoleAuth = true
            this.current = row;
        }
    }
}
